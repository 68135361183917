import { $emit } from 'vue-happy-bus'
import Constant from '../utils/constant'
export default {
    debug: true,
    state: {
        companyData: {}
    },
    setCompanyData (newValue) {
        if (this.debug) console.log('CompanyData triggered with', newValue)
        this.state.companyData = newValue
        $emit(Constant.COMPANY_DATA_UPDATE, newValue)
      },
    clearCompanyData () {
        if (this.debug) console.log('CompanyData triggered')
        this.state.companyData = {}
    }
}
<template>
  <router-view v-slot="{ Component }" >
    <keep-alive>
      <component :is="Component"  v-if="$route.meta.keepAlive" :key="$route.path" v-loading="isLoading"/>
    </keep-alive>
    <component :is="Component"  v-if="!$route.meta.keepAlive" v-loading="isLoading"/>
  </router-view>
</template>

<script>
import OfficialWebsite from "@/api/OfficialWebsite"
import Company from '@/utils/company'
export default {
  name: 'App',
  data() {
    return {
      isLoading: false
    }
  },
  components: {
  },
  created() {
    this.queryCompany()
  },
  methods: {
    queryCompany() {
      if (Company.state.companyData != null && Company.state.companyData['companyId'] != null) {
        return
      }
      this.isLoading = true
      const hostname = window.location.hostname
      OfficialWebsite.queryCompany(hostname).then(({ data }) => {
        this.isLoading = false
        if (data != null && Object.keys(data).length > 0) {
          document.title = `${data.companyName}官网`
          Company.setCompanyData(data)
        }
      }).catch(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style>
</style>

<template>
    <div class="pc-footer">
      <div class="pc-fr-cnt">
        <p>
          Copyright©2018-2019 All Rights Reserved {{companyData.companyName}}
        </p>
        <div style="width: 100%; text-align: center">
          <img src="../assets/gh-icon.png" class="ghicon" alt="" />
          <div style="color: white; font-size: 14px;" @click="toMiit(item)" :key="i" v-for="(item, i) in companyBeianData">
            {{item.name}} {{item.value}}
            
          </div>
        </div>

      </div>
    </div>
</template>

<script>
import Company from '@/utils/company'
import Constant from '@/utils/constant'
import { $on } from 'vue-happy-bus'
export default {
  name: "NavigationView",
  data() {
    return {
      companyData: {},
      companyBeianData: []
    }
  },
  created() {
    this.companyData = Company.state.companyData
    this.loadCompanyData()
    $on(Constant.COMPANY_DATA_UPDATE, (value) => {
      this.companyData = value
      this.loadCompanyData()
    })
  },
  methods: {
    loadCompanyData() {
      if(this.companyData.companyBeianNo != null && this.companyData.companyBeianNo != 'undefined') {
      this.companyBeianData = JSON.parse(this.companyData.companyBeianNo)
    }
    },
    toMiit(item) {
      if(item.url != null && item.url != '') {
        window.location.href = item.url
      }
      
    }
  }
}
</script>

<style lang="less" scoped>
.pc-footer {
    width: 100%;
    height: 198px;
    position: relative;
    background-color: #313131;

    .pc-fr-cnt {
        width: 1086px;
        height: 198px;
        margin: 0 auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;

        p {
            width: 100%;
            text-align: center;
            font-size: 18px;
            color: #ffffff;
            padding: 13px 0;

            .ghicon {
                width: 18px;
                height: 20px;
                margin-right: 15px;
            }

            a {
                display: inline-block;
                color: #fff;
                margin: 0 20px;
                text-decoration: underline;
            }
        }
    }
}
</style>
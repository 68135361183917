<template>
  <div class="h5-content">
    <div class="h5xz-top">
      <div class="xzapp-logo">
        <img :src="productData.dpLogoUrl" alt="" />
      </div>
      <div class="xzapp-txt">
        <h1>{{productData.dpName}}</h1>
        <p> <span>{{productData.dpDownloadsNumber}}</span></p>
      </div>
      <a @click="download" class="xzapp-btn">下载</a>
    </div>
    <div class="h5app-lbt">
      <el-carousel :interval="5000" arrow="always" type="card" ref="banner">
        <el-carousel-item v-for="item in productData.dpPreViewImageUrl" :key="item">
          <img :src="item" :style="{'height': 200}">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="xzapp-js-box">
      <h1>应用介绍</h1>
      <p>
        {{productData.dpDetailed}}
      </p>
      <ul class="h5-shzt-list">
        <li>无病毒</li>
        <li>实用工具</li>
        <li>人工亲测</li>
        <li>绿色应用</li>
      </ul>
    </div>
    <div class="h5pf-box">
      <div class="h5fp-l">
        <h1>评分</h1>
        <ul>
          <li><img src="@/assets/xx-icon01.png" alt="" /></li>
          <li><img src="@/assets/xx-icon01.png" alt="" /></li>
          <li><img src="@/assets/xx-icon01.png" alt="" /></li>
          <li><img src="@/assets/xx-icon01.png" alt="" /></li>
          <li><img src="@/assets/xx-icon02.png" alt="" /></li>
        </ul>
      </div>
      <div class="h5fp-r">4.5</div>
    </div>
    <div class="h5xz-an">
      <a @click="download"> 立即下载</a>
    </div>
    <!-- <ul class="h5app-xginfo">
      <li>应用信息</li>
      <li>权限信息</li>
      <li>问题反馈</li>
    </ul> -->
    <div id="TipsView" class="zz-box" v-if="showTips">
      <div class="zz-cnt"><img src="@/assets/tips-image.png" class="zz-img"/> </div>
    </div>
  </div>
</template>

<script>
import OfficialWebsite from "@/api/OfficialWebsite";
export default {
  name: "index",
  data() {
    return {
      showTips: false,
      productData: {},
    };
  },
  created() {
    this.queryProduct()
  },
  methods: {
    queryProduct() {
      const dpId = this.$route.query["dpId"];
      OfficialWebsite.queryProduct(dpId).then(({ data }) => {
        this.productData = data
        this.$nextTick(() => { 
          this.$refs['banner'].setActiveItem(0)
        })
      });
    },
    download() {
      if(this.isWx()) {
        this.showTips = true
      } else {
        this.directional()
      }
    },
   directional() {
      if (this.isIOS()) {
        if (this.productData['dpStoreIosUrl'] == null) {
          alert('本应用暂未在iOS市场上架，敬请期待~')
          return
        }
        window.location.href = this.productData['dpStoreIosUrl']
      } else {
        window.location.href = this.productData['dpStoreDownloadUrl']
      }
    },
    isWx() {
        var ua = window.navigator.userAgent.toLowerCase();
        return ua.match(/MicroMessenger/i) == 'micromessenger' ? true : false
    },
    isIOS() {
        var u = navigator.userAgent;
        return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    }
  }
};
</script>

<style scoped>
@import "../../../css/reset.css";
@import "../../../css/main.css";
.h5app-lbt /deep/.el-carousel__container{height: 6.8rem;}            
.h5app-lbt /deep/img{ width: 100%;}
.zz-box{ z-index: 1000; width:100%; height: 100%; position: fixed; top:0; left:0; }
.zz-cnt{ min-width:320px; max-width:750px; margin: 0 auto; width:100%; height: 100%; position:relative;}
.zz-img{ width: 100%; height: 100%; position:absolute; top:0; left:0;}
</style>
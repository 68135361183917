import { createRouter, createWebHashHistory } from "vue-router";
import Home from '@/pages/index/home/index'
import ProductDetails from '@/pages/index/productDetails/index'
import Download from '@/pages/index/h5Download/index'

const routes = [
    { path: "/", redirect: "/Home" },
    {
        path: "/Home",
        name: "Home",
        component: Home
    },
    {
        path: "/ProductDetails",
        name: "ProductDetails",
        component: ProductDetails
    },
    {
        path: "/Download",
        name: "Download",
        component: Download
    }
]

export default createRouter({
    history: createWebHashHistory(),
    routes: routes
})
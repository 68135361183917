<template>
    <div class="pc-header">
      <div class="pc-header-c">
        <div class="logo">
          <img :src="companyData.companyLogoUrl" alt="" />
        </div>
        <ul class="nav-list">
          <li @click="toPage(item)" :class="{'active': i == defaultSelected}" v-for="(item, i) in menuArray" :key="i"><a href="javascript:">{{item.title}}</a></li>
        </ul>
      </div>
    </div>
</template>

<script>
import Constant from '@/utils/constant'
import Company from '@/utils/company'
import { $on } from 'vue-happy-bus'
export default {
  name: "NavigationView",
  data() {
    return {
      companyData: {},
      menuArray: [
        {
          title: '首页',
          name: 'Home'
        },
        {
          title: '产品中心',
          name: 'Product'
        },        
        {
          title: '关于我们',
          name: 'About'
        }
      ]
    }
  },
  props: {
    defaultSelected: {
      type: Number,
      default: 0
    },
    mode: {
      type: String,
      default: 'HOME'
    }
  },
  created() {
    this.companyData = Company.state.companyData
    $on(Constant.COMPANY_DATA_UPDATE, (value) => {
      this.companyData = value
    })
  },
  methods: {
    toPage(item) {
      if (item.name == null) {
        return
      }
      if (this.mode == 'HOME') {
        this.$emit('menu-click', item['name'])
      } else if (this.mode == 'PRODUCT' && item['name'] != 'Product') {
        this.$router.push({ name: 'Home', params: { 'point': item['name'] }})
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .pc-content {
    max-width: 1920px;
    margin: 0 auto;
  }
  .pc-header {
    z-index: 1000;
    height: 62px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);

  .pc-header-c {
    width: 1086px;
    margin: 0 auto;
    height: 62px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {}

  .nav-list {
    display: flex;
    align-items: center;

    li {
        height: 62px;
        width: 80px;
        margin-left: 100px;

      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        font-size: 18px;
        color: #fff;
        text-align: center;
        line-height: 62px;
        position: relative;
      }

      &.active {
        a {
          &:after {
             content: '';
             display: inline-block;
             width: 80px;
             height: 4px;
             background-color: #0365e9;
             position: absolute;
             bottom: 0;
             left: 0;
           }
          }
        }
      }
    }
  }
</style>
<template>

  <div class="pc-content">

<div class="pc-header-w">
    <navigation-view :defaultSelected="menuDefaultSelected" @menu-click="menuClick"></navigation-view>
</div>
    <div class="pc-banner">

      <div class="banner" v-loading="loadingBanner">
        <el-carousel :interval="5000" ref="banner" >
          <el-carousel-item v-for="item in bannerList" :key="item">
            <img :src="item['cbUrl']" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="pc-br-box">
        <div class="pc-data-box">
          <ul class="pcdata-list">
            <li>
              <h1>2,462,090<i class="dw-txt">次</i></h1>
              <p>用户下载量</p>
            </li>
            <li>
              <h1>71,162<i class="dw-txt">次</i></h1>
              <p>日活跃用户量</p>
            </li>
            <li>
              <h1>276,936<i class="dw-txt">次</i></h1>
              <p>注册用户数</p>
            </li>
            <li>
              <h1>8,228<i class="dw-txt">次</i></h1>
              <p>付费用户量</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="pc-tit-box" ref="pc-tit-box">
      <h1>产品中心</h1>
      <p>专注APP开发，始终把用户体验放到第一位</p>
    </div>

    <div class="pc-cp-box" ref="pc-cp-box">
      <img src="@/assets/cpzx_bg.png" class="bg" alt="" />
      <div class="pc-cp-cnt">
        <a class="xq-btn" v-if="currentPage > 1" @click="previousPage"><img src="@/assets/icon_01_l.png" alt="" /></a>
        <a class="xh-btn" v-if="currentPage < totalPage" @click="nextPage"><img src="@/assets/icon_01_r.png" alt="" /></a>
        <ul class="pc-cp-list">
          <li v-for="(item,i) in currentPageData" :key="i">
            <router-link :to="{'name': 'ProductDetails', query: {'dpId': item['dpId']}}">
              <div class="pccp-img">
                <img :src="item.dpHdImageUrl" alt="" />
              </div>
              <div class="pccp-txt">
                <h1>{{item.dpName}}</h1>
                <p>
                  {{item.dpDetailed}}
                </p>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="pc-tit-box" ref="pc-about-box">
      <h1>关于我们</h1>
      <p>专业的团队，打造易用好用简洁的产品</p>
    </div>

    <div class="gywm-box">
      <img src="@/assets/gywm_ng.png" class="bg" alt="" />
      <div class="gywm-cnt">
        <div class="gywm-c">
          <div class="gywm-c-c">
            <h1>{{companyData.companyName}}</h1>
            <p v-html="companyData.companyAbout">
            </p>
          </div>
        </div>
      </div>
    </div>
    <footer-view />
  </div>
</template>

<script>
import NavigationView from '@/components/NavigationView'
import FooterView from '@/components/FooterView'
import OfficialWebsite from "@/api/OfficialWebsite"
import { $on } from 'vue-happy-bus'
import Company from '@/utils/company'
import Constant from "@/utils/constant";
export default {
  name: "index",
  components: { NavigationView, FooterView },
  data() {
    return {
      productPageSize: 4,
      currentPage: 1,
      totalPage: 1,
      loadingBanner: false,
      companyData: {},
      productList: [],
      currentPageData: [],
      bannerList: [],
      menuDefaultSelected: 0
    }
  },
  created() {
    this.$nextTick(() => {
      this.queryProductList()
      this.queryBannerList()
    })
    window.addEventListener('scroll', this.windowScroll)
    const point = this.$route.params['point']
    if (point == 'Home') {
      this.$nextTick(() => {
        window.scroll(0, 0)
      })
    } else if (point == 'About') {
      this.$nextTick(() => {
        this.$refs['pc-about-box'].scrollIntoView()
      })
    }
    this.companyData = Company.state.companyData
    $on(Constant.COMPANY_DATA_UPDATE, (value) => {
      this.companyData = value
    })
  },
  watch: {
    currentPage(newVal) {
      this.loadingCurrentPage(newVal)
    }
  },
  methods: {
    loadingCurrentPage(page) {
      const start = (page - 1) * this.productPageSize
      const stop = page * this.productPageSize
      var array = []
      for(var i=start; i < stop; i++) {
        if (this.productList[i] != null) {
          array.push(this.productList[i])
        }
      }
      this.currentPageData = array
    },
    nextPage() {
      if(this.currentPage >= this.totalPage) {
        return
      }
      this.currentPage = this.currentPage + 1
    },
    previousPage() {
      if(this.currentPage <= 1) {
        return
      }
      this.currentPage = this.currentPage - 1
    },
    queryProductList() {
      var hostname = window.location.hostname
      OfficialWebsite.queryProductList(hostname).then(({ data }) => {
        this.productList = data
        this.totalPage = Math.ceil(data.length / this.productPageSize)
        this.loadingCurrentPage(1)
      })
    },
    queryBannerList() {
      this.loadingBanner = true
      var hostname = window.location.hostname
      OfficialWebsite.queryBannerList(hostname).then(({ data }) => {
        this.bannerList = data
        this.$nextTick(() => { 
          this.$refs['banner'].setActiveItem(0)
        })
        this.loadingBanner = false
      }).catch(() => {
        this.loadingBanner = false
      })
    },
    windowScroll() {
     if(this.$route['name'] != 'Home') {
       return
     }
     if (this.$refs['pc-tit-box'] == null) {
       return
     }
     const boxBound = this.$refs['pc-tit-box'].getBoundingClientRect()
     const cpBound = this.$refs['pc-cp-box'].getBoundingClientRect()
     const totalHeight = boxBound.height + cpBound.height
     const offset = 62 - totalHeight
     if (boxBound.top >= 62) {
       this.menuDefaultSelected = 0
     } else if (boxBound.top < 62 && boxBound.top > offset) {
       this.menuDefaultSelected = 1
     } else if (boxBound.top <= offset) {
       this.menuDefaultSelected = 2
     }
    },
    menuClick(name) {
      if (name == 'Home') {
        window.scroll(0, 0)
      } else if (name == 'Product') {
        this.$refs['pc-tit-box'].scrollIntoView()
      } else if (name == 'About') {
        this.$refs['pc-about-box'].scrollIntoView()
      }
      
    }
  }
};
</script>

<style scoped>
@import "../../../css/reset.css";
@import "../../../css/main.css";
.el-carousel--horizontal{ overflow: hidden;}
</style>